<template>
	
	<div class="cont" id="p09" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: none; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0;">
			<div class="popup type02" style="width: 350px; margin: 10% auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
				<div class="p-header">
					<div class="title">{{ program.name }}</div>
				</div>
				<div class="p-body">
					<div class="c01">
						<div class="pc-body">
							<div class="item_list">
								<div class="item">
									<div class="title" style="font-size: 1em !important;">반려일</div>
									<div class="desc"  style="font-size: 1em !important;">{{ item.reviewAt }}</div>
								</div>
								<div class="item"
								>
									<div class="title" style="font-size: 1em !important;" >사유</div>
									<div class="desc" style="font-size: 1em !important;">{{ item.reasonForRejection }}</div>
								</div>
							</div>
							
							<div style="margin-top: 30px; font-size: 1em;">사유를 확인하시고 필요시 재심사요청이 가능합니다</div>
						</div>
					</div>
				</div>
				<div class="p-button">
					<v-row>
						<v-col>
							<v-btn
								class="success"
								style="background: #00bfa5;"
								block tile
								:to="{ name: 'MerchantsApplyItem', params: { index: reason.id } }"
							>재심사요청</v-btn>
						</v-col>
						<v-col>
							<v-btn
								class="close"
								style="background: #bbb;"
								block tile
								@click="close"
							>확인</v-btn>
						</v-col>
					</v-row>
					
				</div>
			</div>
		</div>
	</div>

</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	
	export default{
		name: 'AgentPopupItem'
		,props: ['reason']
		,data: function(){
			return {
				program: {
					name: '가맹점 등록 신청 반려'
				}
				,item: {
				}
			}
		}
		,methods: {
			getData: async function(){
				const self = this
				try{
					let result = await Axios({
						method: 'get'
						,url: '/merchants/apply/' + self.reason.id
						,authorize: true
					})
					
					if(result.success){
						this.item = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,close: function(){
				this.$emit('setOverlay', false)
			}
		}
		,watch: {
			reason: {
				immediate: true
				,deep: true
				,handler: function(){
					this.getData()
				}
			}
		}
	}
</script>




