<template>
	
	<div class="cont" id="p11">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="left">
				<div class="s01">
					<div class="c-item">
						<div class="title">신청일</div>
						<div class="content">
							<div class="cont-date">
								
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="시작일"
										v-model="search.inquiryStartDate"
										@click="search.inquiryStartDate = ''; datePicker.start.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.start.view"
										v-model="search.inquiryStartDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 120px; border: 1px solid #bbb;"
										@change="datePicker.start.view = false"
									></v-date-picker>
								</div>
								
								
								<span>~</span>
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="종료일"
										v-model="search.inquiryEndDate"
										@click="search.inquiryEndDate = ''; datePicker.end.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.end.view"
										v-model="search.inquiryEndDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 350px; border: 1px solid #bbb;"
										@change="datePicker.end.view = false"
									></v-date-picker>
								</div>
							</div>
						</div>
					</div>
					<div class="c-item">
					</div>
					<div class="c-item">
						<div class="title">상태</div>
						<div class="content">
							<select
								v-model="search.serviceStatus"
								@change="getData"
							>
								<option 
									v-for="(item, index) in serviceStatus"
									:key="index"
									:value="item.code"
									>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
					</div>
					<div class="c-item">
						<div class="title">검색어</div>
						<div class="content">
							<input type="text" placeholder="상호명, 대표자명, 또는 ID를 입력하세요."
								v-model="search.keyword"
							>
						</div>
					</div>
				</div>
				<div class="s02">
					<a @click="getData"><div class="btn-search">조회</div></a>
					<a @click="reset"><div class="btn-reset">초기화</div></a>
				</div>
			</div>
			<div class="right">
				<div class="group g04">
					<div class="title">
						<div class="gt-l">
							<span class="p1">가맹점 심사 현황</span>
						</div>
						<div class="gt-r">
						</div>
					</div>
					<div class="content02">
						<div class="citem">
							<div class="cp">심사 대기</div>
							<div class="camount"><span class="em">{{ summary.reviewCount }}</span>건</div>
						</div>
						<div class="citem">
							<div class="cp">반려</div>
							<div class="camount"><span class="em">{{ summary.rejectCount }}</span>건</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="c02">
			<div class="table type02">
				<div class="item-header">
					<div class="item">
						<div class="td">NO</div>
						<div class="td">사업자 구분</div>
						<div class="td">상호명(법인명)</div>
						<div class="td">대표자</div>
						<div class="td">상태</div>
						<div class="td">신청일</div>
						<div class="td">상세</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item btn-item"
						v-for="(item, index) in items"
						:key="index"
					>
						<div class="info"
							:class="{ active: item.active}"
						>
							<div class="td"> {{ item.rowNumber }} </div>
							<div class="td"> {{ item.companyTypeName }} </div>
							<div class="td"> {{ item.businessName }} </div>
							<div class="td"> {{ item.ceoName }} </div>
							<div class="td">
								<div class="type01 active">{{ item.serviceStatusName }}</div>
								<div class="type02 active"
									v-if="item.serviceStatus == 'Rejected'"
									@click="viewReason(item.id)"
									><img src="@/assets/img/message.png" alt=""></div>
							</div>
							<div class="td"> {{ item.createAt }} </div>
							<div class="td"> <router-link :to="{ name: 'MerchantsApplyDetail', params: { index: item.id}}">상세보기</router-link> </div>
						</div>
					</div>
					
					<div
						v-show="!items.length"
						class="no-data"
					>
						No Data
					</div>
				</div>

				<div class="text-center mt-10">
					<Pagination 
						:options="search"
					/>
				</div>
			</div>
		</div>
		
		<ConfirmList 
			:overlay="overlay"
			@click="close"
			v-if="false"
		/>
		
		<MerchantsApplyPopupReason
			v-if="showPopup.reason"
			:reason="itemReason"

			@setOverlay="setOverlay"
			@setNotify="setNotify"
		/>
		
	</div>

</template>
<script>
	import { Axios } from '@/resources/axios/axios'
	
	import Pagination from '@/components/Pagination'
	
	import MerchantsApplyPopupReason from '@/view/Merchants/Apply/MerchantsApplyPopupReason'
	
	export default{
		name: 'FranchiseeConfirmList'
		,components: { Pagination, MerchantsApplyPopupReason }
		,props: ['overlay']
		,data: function(){
			return {
				program: {
					name: '가맹점 신청 목록'
				}
				,search: {
					serviceStatus: ''
					,inquiryStartDate: '20200101'
					,inquiryEndDate: ''
				}
				,summary: {
					rejectCount: 0
					,reviewCount: 0
				}
				,items: []
				,serviceStatus: [
					{ code: '', codeName: '전체'}
					,{ code: 'NotApplied', codeName: '심사대기'}
					,{ code: 'Rejected', codeName: '반려'}
					,{ code: 'Re', codeName: '재심사'}
				]
				,datePicker: {
					start: {
						view: false
					}
					,end: {
						view: false
					}
				}
				,showPopup: {
					reason: false
				}
				,itemReason: {
					id: ''
				}
				
			}
		}
		,methods: {
			close: function(){
				this.$emit('click')
			}
			,getData: async function(){
				const search = this.search
				try{
					let result = await Axios({
						method: 'get'
						,url: '/merchants/apply/list'
						,authorize: true
						,data: search
					})
					
					if(result.success){
						this.items = result.data.content.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
				}
			}
			,cancel: async function(id){
				if(confirm(this.$language.common.askRemove)){
					try{
						const result = await Axios({
							method: 'put'
							,url: '/merchants/apply/' + id + '/None'
						})
						if(result.success){
							this.$delete(this.items, id)
						}else{
							this.$emit('setNotify', { type: 'error', message: result.message })
						}
					}catch(E){
						console.log(E)
						this.$emit('setNotify', { type: 'error', message: E})
					}
				}
			}
			,getApplySummary: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: 'merchants/apply/summary'
						,authorize: true
					})
					
					if(result.success){
						this.summary = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			,reset: function(){
				this.search = {
					inquiryStartDate: '20200101'
					,inquiryEndDate: ''
					,keyword: ''
					,serviceStatus: ''
				}
			}
			,viewReason: function(id){
				this.itemReason.id = id
				this.showPopup.reason = true
				this.$emit('setOverlay', true)
			}
			,setOverlay: function(type){
				this.$emit('setOverlay', type)
				if(!type){
					this.showPopup.reason = false
				}
			}
			,setNotify: function(option){
				this.$emit('setNotify', option)
			}
		}
		,created: function(){
			this.getApplySummary()
			this.getData()
		}
		,watch: {
			'search.inquiryStartDate': {
				handler: function(call){
					this.search.inquiryStartDate = call.replaceAll('-', '')
				}
			}
			,
			'search.inquiryEndDate': {
				handler: function(call){
					this.search.inquiryEndDate = call.replaceAll('-', '')
				}
			}
		}
	}
</script>





